import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { submitFormData } from '@utils/hubspot';
import { isEmailValid } from '@utils/helpers';

import Button from '@components/button';
import Text, { TEXT_COLOR } from '@components/text';

import {
  Wrapper, ContentWrapper, FormContainer, StyledForm, StyledInput, StyledTextArea,
  ContactContainer,
  StyledMailIcon, StyledCallIcon, StyledLocationIcon,
} from './styles';

class ContactsSection extends Component {
  constructor() {
    super();
    this.state = {
      values: {
        email: '',
        name: '',
        message: '',
      },
      errors: {
        email: false,
        name: false,
        message: false,
      },
      isDataSubmitted: false,
      hasRequestError: false,
    };
  }

  handleInput = (type) => (e) => {
    const { value } = e.target;
    const { errors, hasRequestError } = this.state;
    if (hasRequestError) {
      this.setState({ hasRequestError: false });
    }
    if (errors[type]) {
      this.setState({
        errors: {
          ...errors,
          [type]: false,
        },
      });
    }
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [type]: value,
      },
    }));
  }

  handleRequestResult = (isDataSubmitted) => {
    if (isDataSubmitted) {
      this.setState({
        values: {
          email: '',
          name: '',
          message: '',
        },
        isDataSubmitted,
      });
    } else {
      this.setState({
        hasRequestError: true,
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { values } = this.state;
    if (this.validateValues(values)) {
      submitFormData({
        name: values.name,
        email: values.email,
        message: values.message,
        page: 'Contact us',
        onRequest: this.handleRequestResult,
      });
    }
  }

  getButtonText = () => {
    const { isDataSubmitted, hasRequestError } = this.state;
    return (isDataSubmitted && 'Thank you')
      || (hasRequestError && 'Error') || 'Send';
  }

  validateValues = (values) => {
    const hasError = Object.keys(values).some((key) => {
      if (!values[key].length) {
        this.setState(({ errors }) => ({
          errors: {
            ...errors,
            [key]: true,
          },
        }));
        return true;
      }
      if (key === 'email' && !isEmailValid(values[key])) {
        this.setState(({ errors }) => ({
          errors: {
            ...errors,
            [key]: true,
          },
        }));
        return true;
      }
      return false;
    });
    return !hasError;
  }

  getButtonText = () => {
    const { isDataSubmitted, hasRequestError } = this.state;
    return (isDataSubmitted && 'Thank you')
      || (hasRequestError && 'Error') || 'Send';
  }

  renderForm = (content) => {
    const {
      values, errors, isDataSubmitted, hasRequestError,
    } = this.state;
    return (
      <FormContainer>
        <Text fontSize={21} color={TEXT_COLOR.HIGHLIGHTED} dangerouslySetInnerHTML={{ __html: content }} />
        <StyledForm>
          <StyledInput
            type="email"
            onChange={this.handleInput('email')}
            value={values.email}
            placeholder="Email"
            disabled={isDataSubmitted}
            hasError={errors.email}
          />
          <StyledInput
            type="text"
            onChange={this.handleInput('name')}
            value={values.name}
            placeholder="Name"
            disabled={isDataSubmitted}
            hasError={errors.name}
          />
          <StyledTextArea
            onChange={this.handleInput('message')}
            value={values.message}
            placeholder="Message"
            disabled={isDataSubmitted}
            hasError={errors.message}
          />
          <Button
            type="submit"
            onClick={this.handleSubmit}
            width={130}
            height={32}
            success={isDataSubmitted}
            alert={hasRequestError}
          >
            {this.getButtonText()}
          </Button>
        </StyledForm>
      </FormContainer>
    );
  }

  renderContacts = (content) => (
    <div>
      <ContactContainer>
        <StyledMailIcon />
        <Text fontSize={21} color={TEXT_COLOR.GRAY} dangerouslySetInnerHTML={{ __html: content[0] }} />
      </ContactContainer>
      <ContactContainer>
        <StyledCallIcon />
        <Text fontSize={21} color={TEXT_COLOR.GRAY} dangerouslySetInnerHTML={{ __html: content[1] }} />
      </ContactContainer>
      <ContactContainer>
        <StyledLocationIcon />
        <Text fontSize={21} color={TEXT_COLOR.GRAY} dangerouslySetInnerHTML={{ __html: content[2] }} />
      </ContactContainer>
    </div>
  );

  render() {
    const { content } = this.props;
    const splittedContent = content.split('<hr>');
    return (
      <Wrapper>
        <ContentWrapper>
          {this.renderForm(splittedContent[0])}
          {this.renderContacts(splittedContent.slice(1))}
        </ContentWrapper>
      </Wrapper>
    );
  }
}

ContactsSection.propTypes = {
  content: PropTypes.string,
};

export default ContactsSection;
