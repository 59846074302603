import styled from '@emotion/styled';
import { getIconsByType, ICON_TYPES } from '@utils/icons';

export const Wrapper = styled.section({
  width: '100%',
  paddingTop: 68,
  marginBottom: 205,
  '@media (max-width: 480px)': {
    paddingTop: 30,
    marginBottom: 50,
  },
});

export const ContentWrapper = styled.div({
  margin: '0 7%',
  display: 'grid',
  gridTemplateColumns: '53% 37%',
  gridGap: '8%',
  alignItems: 'center',
  '@media (max-width: 480px)': {
    margin: '0 10%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

export const FormContainer = styled.div({
  padding: '20px 30px',
  borderRadius: 5,
  '@media (max-width: 480px)': {
    width: '100%',
    padding: '15px 25px 20px',
    marginBottom: 66,
    textAlign: 'center',
    '& h4': {
      fontSize: 21,
      marginBottom: 14,
    },
  },
},
({ theme }) => ({
  boxShadow: theme.shadows.contactForm,
}));

export const StyledForm = styled.form({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'center',
  '@media (max-width: 480px)': {
    flexDirection: 'column',
    alignItems: 'center',
    '& button': {
      fontSize: 12,
    },
  },
});

const getBorderColor = (theme, hasError, disabled) => {
  if (hasError) return theme.colors.buttonAlert;
  if (disabled) return theme.colors.borderDisabled;
  return theme.colors.textInputBorder;
};

const inputStyles = [
  {
    padding: 10,
    borderRadius: 5,
    border: '1px solid',
    outline: 'none',
    fontSize: 15,
    '@media (max-width: 480px)': {
      width: '100%',
      margin: '0 0 7px 0',
      fontSize: 12,
    },
  },
  ({ theme, hasError, disabled }) => ({
    color: theme.colors.textGray,
    borderColor: getBorderColor(theme, hasError, disabled),
    backgroundColor: theme.colors.background,
    '&:focus': {
      backgroundColor: theme.colors.textInputBackground,
    },
    '&::placeholder': {
      color: disabled ? theme.colors.textDisabled : theme.colors.textLightGray,
    },
  }),
];

export const StyledInput = styled.input(
  ...inputStyles,
  {
    height: 50,
    margin: '13px 0',
    '@media (max-width: 480px)': {
      height: 32,
    },
  },
  ({ type }) => ({
    width: type === 'email' ? '60%' : '35%',
    marginRight: type === 'email' ? '5%' : '0',
  })
);

export const StyledTextArea = styled('textarea')(
  ...inputStyles,
  {
    height: 124,
    width: '100%',
    marginBottom: 27,
    '@media (max-width: 480px)': {
      height: 137,
      marginBottom: 20,
      resize: 'none',
    },
  }
);

export const ContactContainer = styled.div({
  display: 'flex',
  margin: '28px 0',
  '@media (max-width: 480px)': {
    margin: '0 0 27px 0',
    '& p': {
      fontSize: 15,
    },
  },
});

const iconStyles = [{
  marginRight: 14,
  fontSize: 26,
  fill: 'none',
  strokeWidth: '36px',
  '@media (max-width: 480px)': {
    marginTop: -6,
  },
},
({ theme }) => ({
  color: theme.colors.buttonPrimary,
})];

export const StyledMailIcon = styled(getIconsByType(ICON_TYPES.MAIL))(...iconStyles);

export const StyledCallIcon = styled(getIconsByType(ICON_TYPES.CALL))(
  ...iconStyles,
  {
    fontSize: 30,
    marginRight: 10,
  },
);

export const StyledLocationIcon = styled(getIconsByType(ICON_TYPES.LOCATION))(...iconStyles);
