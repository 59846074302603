import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '@components/seo';
import MainLayout from '@layouts/mainLayout';
import ContactsSection from '@components/pages/contact/contacts';

class ContactPage extends Component {
  getSectionContent = (section) => {
    const { data } = this.props;
    return data.cockpitcontact[section].childMarkdownRemark.html;
  }

  render() {
    return (
      <MainLayout>
        {/* TODO: add keywords */}
        <SEO title="Contact us" keywords={[]} />
        <ContactsSection content={this.getSectionContent('contacts')} />
      </MainLayout>
    );
  }
}

ContactPage.propTypes = {
  data: PropTypes.object,
};

export default ContactPage;

export const query = graphql`
  query ContactQuery {
    cockpitcontact {
      contacts: childCockpitcontactContactsTextNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
